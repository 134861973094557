import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import { kebabCase } from "lodash";

import Layout from "../components/layout";

import { Header } from "../components/03_organisms/header";
import { Footer } from "../components/03_organisms/footer";
import { Bio } from "../components/03_organisms/bio";

// Components
const TagsPage = ({ data }) => {
  const tagHeader = "タグ一覧";
  const pageUrl = data.site.siteMetadata.siteUrl + "tags/";
  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={tagHeader + " | " + data.site.siteMetadata.title}
        meta={[
          {
            name: "description",
            content: tagHeader + " | " + data.site.siteMetadata.description,
          },
          {
            name: "keywords",
            content: tagHeader + " " + data.site.siteMetadata.keywords,
          },
          {
            property: "og:title",
            content: tagHeader + " | " + data.site.siteMetadata.title,
          },
          {
            property: "og:description",
            content: tagHeader + " | " + data.site.siteMetadata.description,
          },
          { property: "og:url", content: pageUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: data.site.siteMetadata.title },
          { property: "twitter:card", content: "summary" },
          {
            property: "twitter:site",
            content: data.site.siteMetadata.twitterUsername,
          },
          {
            property: "google-site-verification",
            content: data.site.siteMetadata.google_site_verification,
          },
          //{ property: 'og:image', content: ''},
          //{ property: 'og:image', content: mushiIcon },
        ]}>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <script>
          {`
        (adsbygoogle = window.adsbygoogle || []).push({
            google_ad_client: "ca-pub-8068277400499023",
            enable_page_level_ads: true
          });
        `}
        </script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: "0 auto",
          maxWidth: "42rem",
          padding: "0px 1.0875rem 1.45rem",
          paddingTop: 0,
        }}>
        <Bio />
        <h3>{tagHeader}</h3>
        {data.allMarkdownRemark.group.map((value, id) => (
          <div
            key={id}
            style={{
              borderBottom: "1px solid #ebf2f6",
            }}>
            <Link
              to={`/tags/${kebabCase(value["fieldValue"])}/`}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}>
              <h3 style={{}}>
                {value["fieldValue"]} ({value["totalCount"]})
              </h3>
            </Link>
          </div>
        ))}
      </div>
      <Footer />
    </Layout>
  );
};

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
